<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-demo__sysnoticeuser">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <ren-select v-model="dataForm.type" dict-type="notice_type" :placeholder="$t('notice.type')"></ren-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t("query") }}</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" border @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="id" label="ID" header-align="center" align="center"></el-table-column>
        <el-table-column prop="id" label="服务单ID" header-align="center" align="center">
          <template slot-scope="scope">
            {{ getService(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="title" :label="$t('notice.title')" header-align="center" align="center"></el-table-column>

        <el-table-column prop="type" :label="$t('notice.type')" header-align="center" align="center" width="150">
          <template slot-scope="scope">
            {{ $getDictLabel("notice_type", scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="senderName" :label="$t('notice.senderName')" header-align="center" align="center" width="150"></el-table-column>
        <el-table-column prop="senderDate" :label="$t('notice.senderDate')" header-align="center" align="center" width="170"></el-table-column>
        <el-table-column prop="readStatus" :label="$t('notice.readStatus')" header-align="center" align="center" width="130">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.readStatus === 0" size="small" type="danger">{{ $t("notice.readStatus0") }}</el-tag>
            <el-tag v-else size="small" type="success">{{ $t("notice.readStatus1") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="viewHandle(scope.row)">{{ $t("notice.view") }}</el-button>
            <el-button type="text" size="small" @click="toDealWithHandle(scope.row)"> 处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from "@/mixins/view-module";
import { addDynamicRoute } from "@/router";
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: "/sys/notice/mynotice/page",
        getDataListIsPage: true,
      },
      dataForm: {
        type: "",
      },
    };
  },
  methods: {
    viewHandle(row) {
      // 路由参数
      const routeParams = {
        routeName: `${this.$route.name}__${row.id}`,
        title: this.$t("notice.view2"),
        path: "notice/notice-user-view",
        params: {
          id: row.id,
        },
      };

      // 如果未读，则标记为已读
      if (row.readStatus === 0) {
        this.updateReadStatus(row.id);
      }

      // 动态路由
      addDynamicRoute(routeParams, this.$router);
    },
    updateReadStatus(noticeId) {
      this.$http["put"]("/sys/notice/mynotice/read/" + noticeId)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    getService(row) {
      let contentID = "";
      if (row.type === 3) {
        if (row.content) {
          contentID = JSON.parse(row.content).ticketId;
        }
      }
      return contentID;
    },
    toDealWithHandle(row) {
      if (row.type !== 3) {
        return this.$message.error("只能处理服务单类型");
      } else {
        if (row.content) {
          let ticketId = JSON.parse(row.content).ticketId;
          let rightId = JSON.parse(row.content).rightId;
          switch (rightId) {
            case 4: // 住院垫付
            case 5: //出院垫付
            case 13: //押金垫付
              this.$router.push({
                path: "/advanceDetails",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 10:
            case 46: //普通二诊
            case 23: //MDT多学科会诊
            case 24: //医疗报告解读
            case 39: //视屏健康会诊
            case 40: //癌症国内二诊
              this.$router.push({
                path: "/seriousillnessDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 11: //协助办理出院
              this.$router.push({
                path: "/assistLeaveHospitalDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 42: //海外二次诊疗
            case 43: //癌症海外二诊
            case 44: //重疾海外二诊
            case 41: //海外二诊及海外就医协助
            case 26: //海外就医协助
              this.$router.push({
                path: "/OverseasClassDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case "35": //定期随访
            case "36": //用药提醒
            case "37": //康复指导
            case "38": //心理咨询
              this.$router.push({
                path: "/controlAndConsultationDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 2: // 门诊绿通
            case 17: // 检查绿通
            case 18: // 手术绿通
            case 1: // 住院绿通
            case 29: // 住院/手术绿通
            case 30: // 重疾门诊绿通
            case 31: // 癌症门诊绿通
            case 32: // 重疾手术/住院加快绿通（含陪诊）
            case 33: // 癌症手术/住院加快绿通（含陪诊）
            case 19: // 无等候就诊
            case 12: // 就医陪伴
            case 34: // 就医陪伴（  含产科）
            case 52: // 专属护理
            case 3: // 专属护理（bigtree）
              this.$router.push({
                path: "/healthticketsDetails",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 48: // 肿瘤基因筛查
            case 47: // 复诊预约挂号
            case 49: // 质子重离子绿通
              this.$router.push({
                path: "/greenHospitaltwoDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 20: //中医上门理疗
              this.$router.push({
                path: "/chineseMedicineDetail",
                query: {
                  id: ticketId,
                },
              });
              break;
            case 58: //
            case 59: //
            case 60: //
            case 61: //
            case 62: //
            case 63: //
            case 64: //
            case 65: //
              this.$router.push({
                path: "/exclusiveCareDetails",
                query: {
                  id: ticketId,
                },
              });
              break;
              case 71: //
              this.$router.push({
                path: "/geneticTestingDetails",
                query: {
                  id: ticketId,
                },
              });
              break;
          }
        }
      }
      // 路由参数
      // const routeParams = {
      //   routeName: `${this.$route.name}__${row.id}`,
      //   title: this.$t("notice.view2"),
      //   path: "notice/notice-user-view",
      //   params: {
      //     id: row.id,
      //   },
      // };
      // 如果未读，则标记为已读
      if (row.readStatus === 0) {
        this.updateReadStatus(row.id);
      }
      // 动态路由
      // addDynamicRoute(routeParams, this.$router);
    },
  },
};
</script>
